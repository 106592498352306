import React, { useEffect } from "react";
import { LazyMotion } from "framer-motion";
import loadable from "@loadable/component";
import { useAppState } from "@state";
import { PageSizeObserver } from "@base";

const Header = loadable(() => import("@organisms/Header"));
const Modal = loadable(() => import("@organisms/Modal"));
const Sidebar = loadable(() => import("@organisms/Sidebar"));
const GdprBanner = loadable(() => import("@organisms/GdprBanner"));
const Userback = process.env.GATSBY_USERBACK_TOKEN
  ? loadable(() => import("@organisms/Userback"))
  : null;
const Announcement = loadable(() => import("@organisms/Announcement"));

const createPromise = () => {
  let args;
  // eslint-disable-next-line no-return-assign, no-promise-executor-return
  const promise = new Promise((..._) => (args = _));
  return [promise, ...args];
};

const [promise, resolve] = createPromise();
const loadFeatures = () => promise;

const DefaultLayout = ({ children, pageContext }) => {
  // load animation features
  useEffect(() => {
    resolve(import("@utils/animationFeatures").then(r => r.domMax));
  }, []);

  const [, dispatch] = useAppState();

  // set lang
  useEffect(() => {
    dispatch({ type: "setLang", lang: pageContext?.lang || "en" });
  }, []);

  // the layout
  return (
    <LazyMotion features={loadFeatures}>
      <div className="relative flex min-h-screen flex-col">
        <Announcement />
        <PageSizeObserver />
        <Header locales={pageContext?.locales} pageLang={pageContext?.lang} />
        {children}
        <GdprBanner />
        <Modal />
        <Sidebar />
        {process.env.GATSBY_USERBACK_TOKEN && <Userback />}
        {/* uncomment if embedding facebook posts */}
        {/* <div id="fb-root" /> */}
      </div>
    </LazyMotion>
  );
};

export default DefaultLayout;
