const hasGdprConsent = () => {
  if (typeof window !== "undefined") {
    if (window.localStorage.getItem(`gdpr_consent`) === "true") {
      // extend consent
      window.localStorage.setItem(`gdpr_consent`, true);
      return true;
    }
  }
  return false;
};

const setGdprConsent = () => {
  window.localStorage.setItem(`gdpr_consent`, true);
};

export default { hasGdprConsent, setGdprConsent };
