/**
 *
 * This page container contains the main tag and transition logic. It should
 * wrap all pages in their respective template files. If used in DefaultLayout,
 * it cannot access Transition State.
 *
 */

import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { m } from "framer-motion";
import loadable from "@loadable/component";
import { useAppState } from "@state";

const Footer = loadable(() => import("@organisms/Footer"));

const PageContainer = ({ children, className, location, flex }) => {
  // eslint-disable-next-line no-empty-pattern
  const [{ layout }, dispatch] = useAppState();
  const { isTransitioning } = layout;

  const [hash, setHash] = useState(undefined);

  // handle scroll effect when visiting cross page anchor links
  useEffect(() => {
    if (!isTransitioning && hash) {
      document.getElementById(hash)?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [hash, isTransitioning]);

  const variants = {
    enter: {
      opacity: 1,
      transition: {
        type: "tween",
        ease: "easeInOut",
        duration: 0.6,
      },
    },
    exit: {
      opacity: 0,
      transition: {
        type: "tween",
        ease: "easeInOut",
        duration: 0.6,
      },
    },
  };

  return (
    <m.div
      key={location?.pathname}
      initial="exit"
      animate="enter"
      exit="exit"
      variants={variants}
      onAnimationComplete={definition => {
        if (definition === "enter") {
          if (typeof window !== "undefined") {
            const dataLayer = window.dataLayer || [];
            dataLayer.push({ event: "routeChange" });
            if (window.location?.hash) {
              setHash(window.location.hash.split("#")[1]);
            }
            dispatch({
              type: "endTransition",
            });
          }
        }
      }}
      className={clsx("relative flex min-h-screen grow flex-col", className)}
    >
      <article
        className={clsx("-mt-20 grow", {
          "flex flex-col items-center justify-center": flex,
        })}
      >
        {children}
      </article>
      <Footer />
    </m.div>
  );
};

export default PageContainer;
