import React, { useState } from "react";
import clsx from "clsx";
import {
  InputText,
  InputTextarea,
  InputCheckbox,
  InputRadio,
  InputSelect,
} from "@base";
import dark from "@constants/dark";
import Text from "./Text";

const Input = ({
  label,
  hideLabel,
  type,
  placeholder,
  name,
  singleLine,
  formColor,
  formState,
  errorState,
  errorMessage,
  className: _className,
  value,
  required,
  options,
  id,
  group,
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [form, setForm] = formState || useState({});
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [error] = errorState || useState([]);
  const hasError = error?.includes(name);
  const [checkedList, setCheckedList] = useState([]);

  let Field;
  switch (type) {
    case "text":
      Field = InputText;
      break;
    case "textarea":
      Field = InputTextarea;
      break;
    case "checkbox":
      Field = InputCheckbox;
      break;
    case "radio":
      Field = InputRadio;
      break;
    case "select":
      Field = InputSelect;
      break;
    default:
      Field = InputText;
      break;
  }

  // Only have the group work with checkboxes and radio buttons
  // If wanting to have multiple <Input/>s, use a <FieldSet /> as a container:
  // <FieldSet>
  //   <Input>
  //   <Input>
  //   <Input>
  // </FieldSet>
  if (group?.length && (type === "checkbox" || type === "radio")) {
    return (
      <fieldset
        className={clsx(
          {
            "text-orange": hasError && formColor !== "yellow",
            "text-blue": hasError && formColor === "yellow",
          },
          _className
        )}
      >
        {label && (
          <legend className={clsx("mb-2", { "sr-only": hideLabel })}>
            <span className="flex">
              {label}{" "}
              {required && (
                <span
                  className={clsx({
                    "text-orange": formColor !== "yellow",
                    "text-blue": formColor === "yellow",
                  })}
                >
                  *
                </span>
              )}
            </span>
          </legend>
        )}
        {group?.map(_g => {
          return (
            <div
              key={`${id}`}
              className={clsx({
                "mb-2 flex items-center": singleLine || _g?.singleLine,
                "mb-2 flex flex-row-reverse items-center justify-end":
                  type === "checkbox" || type === "radio",
              })}
            >
              <label
                htmlFor={_g?.id || name}
                className={clsx("block", {
                  "mb-2":
                    !singleLine && type !== "checkbox" && type !== "radio",
                  "ml-2": type === "checkbox" || type === "radio",
                  "mr-2": singleLine || _g?.singleLine,
                  "text-white": dark.includes(formColor),
                })}
              >
                {_g?.label}
              </label>
              <Field
                {..._g}
                id={_g?.id || name}
                name={_g?.name || name}
                value={_g.value}
                formState={[form, setForm]}
                hasError={hasError}
                groupState={
                  type === "checkbox" ? [checkedList, setCheckedList] : null
                }
                type={type}
                formColor={formColor}
              />
            </div>
          );
        })}
        {errorMessage && hasError && (
          <Text
            variant="xs"
            className={clsx("mt-1", {
              "text-orange": formColor !== "yellow",
              "text-blue": formColor === "yellow",
            })}
          >
            {errorMessage}
          </Text>
        )}
      </fieldset>
    );
  }

  return (
    <div className={clsx(_className)}>
      <div
        className={clsx("relative", {
          "flex items-center": singleLine,
          "flex flex-row-reverse items-center justify-end":
            type === "checkbox" || type === "radio",
          "text-orange": hasError && formColor !== "yellow",
          "text-blue": hasError && formColor === "yellow",
        })}
      >
        <label
          htmlFor={id || name}
          className={clsx("block text-sm", {
            "mb-2": !singleLine && type !== "checkbox" && type !== "radio",
            "mr-2": singleLine,
            "ml-2": type === "checkbox" || type === "radio",
            "sr-only": hideLabel,
            "text-white": dark.includes(formColor),
          })}
        >
          <span className="flex">
            {label}{" "}
            {required && (
              <span
                className={clsx({
                  "text-orange": formColor !== "yellow",
                  "text-blue": formColor === "yellow",
                })}
              >
                *
              </span>
            )}
          </span>
        </label>

        <Field
          name={name}
          id={id || name}
          value={value}
          placeholder={placeholder}
          formState={[form, setForm]}
          hasError={hasError}
          options={options}
          type={type}
          formColor={formColor}
        />
      </div>
      {errorMessage && hasError && (
        <Text
          variant="xs"
          className={clsx("mt-1", {
            "text-orange": formColor !== "yellow",
            "text-blue": formColor === "yellow",
          })}
        >
          {errorMessage}
        </Text>
      )}
    </div>
  );
};

export default Input;
