import React from "react";

const Wrapper = ({
  children,
  className: _className,
  component: Component,
  condition,
  tag: Tag = "div",
  ...props
}) => {
  if (condition) {
    return (
      <Component className={_className} {...props}>
        {children}
      </Component>
    );
  }
  return <Tag className={_className}>{children}</Tag>;
};

export default Wrapper;
