import React, { useState, useRef, useEffect } from "react";
import clsx from "clsx";
import { m } from "framer-motion";
import Icon from "./Icon";

// todo: add documentation

const variants = {
  open: {
    height: "auto",
    opacity: 1,
    display: "block",
    transition: {
      type: "tween",
      duration: 0.25,
      ease: "easeIn",
    },
  },
  close: {
    height: 0,
    opacity: 0,
    transition: {
      type: "tween",
      duration: 0.25,
      ease: "easeIn",
    },
    transitionEnd: {
      display: "none",
    },
  },
};

const Dropdown = ({
  className: _className,
  model,
  options,
  placeholder = "Select One",
  inverse,
  size,
  id = "dropdown",
  label,
}) => {
  const [isActive, setIsActive] = useState(false);
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [value, setValue] = model || useState();
  const dropdown = useRef();

  // close menu if it's open and you click outside of it
  const handleClickOutside = event => {
    if (dropdown.current && !dropdown.current.contains(event.target)) {
      setIsActive(false);
    }
  };

  // change state and close menu when a dropdown item is selected
  useEffect(() => {
    setIsActive(false);
  }, [value]);

  // add/remove listener
  useEffect(() => {
    if (isActive) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("touchstart", handleClickOutside);
    }
    if (!isActive) {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    }
  }, [isActive]);

  // eslint-disable-next-line global-require

  let textSize;
  let iconSize;

  switch (size) {
    case "xs":
      textSize = "text-xs";
      iconSize = "w-1.5 h-1.5";
      break;
    case "sm":
      textSize = "text-sm";
      iconSize = "w-2 h-2";
      break;
    case "md":
      textSize = "text-md";
      iconSize = "w-2.5 h-2.5";
      break;
    case "lg":
      textSize = "text-lg";
      iconSize = "w-3 h-3";
      break;
    default:
      textSize = "text-md";
      iconSize = "w-2.5 h-2.5";
      break;
  }

  return (
    <div ref={dropdown} className={clsx("relative inline-block", _className)}>
      <m.button
        type="button"
        aria-label="expand the dropdown"
        aria-expanded={isActive}
        aria-controls={id}
        aria-haspopup="listbox"
        onTap={toggleActive}
        className={clsx(
          "flex items-center justify-between py-2",
          {
            "space-x-1": size === "xs",
            "space-x-2": size !== "xs",
            "bg-blue-dark text-white": inverse,
            "bg-transparent text-blue": !inverse,
          },
          textSize
        )}
      >
        <span className="whitespace-nowrap text-left">
          {value != null && value !== ""
            ? options?.[value]?.title
            : placeholder}
        </span>
        {/* dropdown Icon */}
        <Icon
          name="chevron"
          className={clsx(
            "icon transform text-black duration-300",
            {
              "-rotate-90": isActive,
              "rotate-90": !isActive,
            },
            iconSize
          )}
        />
      </m.button>
      <m.ul
        id={id}
        role="listbox"
        aria-label={label || placeholder || `dropdown--${id}`}
        tabIndex="-1"
        initial="close"
        animate={isActive ? "open" : "close"}
        variants={variants}
        className={clsx(
          "absolute left-0 z-40 h-0 max-h-[20rem] overflow-auto",
          {
            "bg-blue-dark text-white": inverse,
            "bg-white text-blue": !inverse,
            "shadow-md": isActive,
            "shadow-none": !isActive,
          },
          textSize
        )}
      >
        {placeholder.length > 1 && (
          <m.li
            role="option"
            aria-selected={false}
            className={clsx(
              "cursor-pointer whitespace-nowrap p-4 text-left opacity-60",
              textSize
            )}
            disabled
          >
            {placeholder}
          </m.li>
        )}
        {options?.map((option, i) => {
          return (
            <m.li
              role="option"
              aria-selected={value === option.uid || value === option.id}
              key={option.uid || option.id || option.title || option.value}
              onTap={() => setValue(i)}
              className="w-full cursor-pointer whitespace-nowrap p-4 text-left transition duration-300 hover:bg-blue-lighter"
              tabIndex={isActive ? "0" : "-1"}
            >
              {option.title}
            </m.li>
          );
        })}
      </m.ul>
    </div>
  );
};

export default Dropdown;
