import React, { useEffect, useRef } from "react";
import clsx from "clsx";
import { useAppState } from "@state";

const PageSizeObserver = () => {
  const [, dispatch] = useAppState();
  const el = useRef();
  useEffect(() => {
    // create observer
    let elObserver;
    if (el.current) {
      elObserver = new ResizeObserver(entries => {
        entries.forEach(() => {
          dispatch({
            type: "setPageSize",
            pageSize: {
              width: document.body.scrollWidth,
              height: document.body.scrollHeight,
            },
          });
        });
      });
      // elObserver.observe(el.current.parentElement);
      elObserver.observe(el.current);
    }
    return () => {
      // disconnect observer
      if (elObserver) {
        elObserver.disconnect();
      }
    };
  }, []);
  return (
    <div
      ref={el}
      className={clsx("pointer-events-none absolute inset-0 z-[-9999]")}
    />
  );
};

export default PageSizeObserver;
