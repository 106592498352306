import React from "react";
import clsx from "clsx";

import { useAppState } from "@state";

import { Link } from "gatsby";

const AppLink = ({
  to: _url,
  title,
  className: _className,
  children,
  onClick,
  activeClassName,
  style,
  partiallyActive = true,
  noDispatch,
}) => {
  // eslint-disable-next-line no-empty-pattern
  const [{ translation }, dispatch] = useAppState();
  const lang = translation;

  if (_url && typeof _url === "string") {
    const craftUrl = process.env.GATSBY_CRAFT_BASE_URL;
    const siteUrl = process.env.GATSBY_SITE_URL;
    let url = _url
      .replace(craftUrl, "")
      .replace(siteUrl, "")
      .replace("home", "");

    const handleClick = e => {
      // e.preventDefault();
      if (onClick) {
        onClick(e);
      }

      if (!noDispatch) {
        dispatch({
          type: "hideNav",
        });

        // Trigger page transition
        dispatch({
          type: "startTransition",
          to: url,
        });
      }
    };

    // handle internal links
    // usually internal router and/or transition
    if (
      url.includes(siteUrl) ||
      (!url.includes("https://") &&
        !url.includes("http://") &&
        !url.includes("mailto:") &&
        !url.includes("tel:"))
    ) {
      // Add a preceding slash to any relative urls
      if (url.indexOf("http") === -1 && url.charAt(0) !== "/") {
        url = `/${url}`;
      }
      // Redirect to Spanish pages
      if (lang === "es" && !url.match(/^\/es/)) {
        url = `/es${url}`;
      }
      return (
        <Link
          className={clsx("cursor-pointer", _className)}
          // activeStyle={{ cursor: "default" }}
          activeClassName={activeClassName}
          partiallyActive={partiallyActive}
          title={title}
          name={title}
          to={url}
          onClick={handleClick}
          style={style}
        >
          {children}
        </Link>
      );
    }

    // navigate to other site in same window
    if (url.includes(process.env.GATSBY_OTHER_SITE)) {
      return (
        <a
          href={url || ""}
          title={title}
          name={title}
          className={clsx("cursor-pointer", _className)}
          onClick={onClick}
          style={style}
        >
          {children}
        </a>
      );
    }

    // handle asset links
    if (url.includes(process.env.GATSBY_ASSET_BASE_URL)) {
      return (
        <a
          href={url.replace(process.env.GATSBY_ASSET_BASE_URL, "/files")}
          title={title}
          name={title}
          className={clsx("cursor-pointer", _className)}
          onClick={onClick}
          style={style}
          target="_blank"
          rel="noreferrer"
        >
          {children}
        </a>
      );
    }

    // handle external links
    // usually open in new tab, noreferer, noopener
    return (
      <a
        href={url}
        title={title}
        name={title}
        target="_blank"
        rel="noopener noreferrer"
        className={clsx("cursor-pointer", _className)}
        onClick={onClick}
        style={style}
      >
        {children}
      </a>
    );
  }

  if (onClick && !_url) {
    return (
      <button
        type="button"
        aria-label={title}
        onClick={onClick}
        className={clsx("cursor-pointer", _className)}
        style={style}
      >
        {children}
      </button>
    );
  }

  // return a span if no link/button functionality
  return <span className={clsx(_className)}>{children}</span>;
};

export default AppLink;
