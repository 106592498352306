import React from "react";
import clsx from "clsx";

const InputRadio = ({
  name,
  placeholder,
  formState,
  hasError,
  value,
  id,
  className: _className,
}) => {
  const [form, setForm] = formState;

  return (
    <input
      type="radio"
      name={name}
      id={id || name}
      value={value}
      placeholder={placeholder}
      className={clsx(_className, {
        "border-orange": hasError,
      })}
      onChange={e => {
        setForm({ ...form, [name]: e.target.value });
      }}
    />
  );
};

export default InputRadio;
