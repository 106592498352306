import React from "react";
import clsx from "clsx";
import { AppLink } from "@base";
import Icon from "./Icon";

const Button = ({
  className: _className,
  color = "orange",
  to,
  title,
  type = "button",
  onClick,
  linkObj: LinkObj = AppLink,
  children,
  fullWidth,
  size = "md",
  icon = "triangle",
  down,
}) => {
  const defaultClassName = `flex justify-center items-center gap-4 cursor-pointer relative z-10 group text-center leading-tight rounded-t-2xl rounded-br-2xl duration-300 hover:opacity-60`;

  const sizes = {
    lg: "px-5 py-4 text-base",
    md: "px-4 py-2 text-sm",
    sm: "px-3 py-2 text-xs",
    xs: "px-2 py-2 text-xxs",
  };

  const colors = {
    blue: "bg-blue text-white",
    green: "bg-green text-white",
    orange: "bg-orange text-white",
  };

  const classes = clsx(
    defaultClassName,
    sizes[size],
    colors[color],
    _className,
    {
      "w-full": fullWidth,
    }
  );

  if (to && to.length > 1) {
    return (
      <LinkObj to={to} title={title} className={classes} onClick={onClick}>
        <span className="relative text-center leading-tight">{children}</span>
        {icon && (
          <Icon
            name={icon}
            className={clsx("h-auto w-1 text-white", {
              "rotate-90": down,
            })}
          />
        )}
      </LinkObj>
    );
  }
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      aria-label={title}
      className={classes}
      onClick={onClick}
    >
      <span className="relative text-center leading-tight">{children}</span>
      {icon && (
        <Icon
          name={icon}
          className={clsx("h-auto w-1 text-white", {
            "rotate-90": down,
          })}
        />
      )}
    </button>
  );
};

export default Button;
