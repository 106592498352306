/* eslint-disable react/jsx-no-useless-fragment */
import React from "react";
import clsx from "clsx";

const InputText = ({
  name,
  placeholder,
  formState,
  hasError,
  options,
  className: _className,
}) => {
  const [form, setForm] = formState;

  return (
    <select
      name={name}
      id={name}
      className={clsx(
        "w-full max-w-full rounded border border-blue px-2 py-1.5 text-sm leading-none text-black",
        _className,
        {
          "!border-orange": hasError,
        }
      )}
      onChange={e => {
        setForm({ ...form, [name]: e.target.value });
      }}
    >
      <>
        {placeholder && (
          <option key="placeholder" value="">
            {placeholder}
          </option>
        )}
        {options?.map((option, i) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <option key={i} value={option}>
              {option}
            </option>
          );
        })}
      </>
    </select>
  );
};

export default InputText;
