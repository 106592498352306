const dark = [
  "blue",
  "bg-blue",
  "blueDark",
  "bg-blue-dark",
  "green",
  "bg-green",
  "bg-image-dark",
];

export default dark;
